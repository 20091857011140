<template>
  <div class="row items-center justify-between">
    <div>
      <PageHeader
        breadcrumb="Store"
        title="Daily Sales Report"
        subTitle="How Is My Business Doing?"
      />
    </div>

    <div class="q-gutter-md">
      <Button
        variant="primary"
        label="Export"
        size="lg"
        :disabled="!hasData"
        outline
        @click="exportTable"
      >
        <template v-slot:icon>
          <img :src="getIconUrl('icon_export')" width="30" />
        </template>
      </Button>
      <Button
        variant="primary"
        label="Help"
        size="lg"
        outline
        @click="onToggleModal"
      >
        <template v-slot:icon>
          <img :src="getIconUrl('icon_how_to')" width="30" />
        </template>
      </Button>
    </div>
  </div>

  <PageContent>
    <div class="col q-col-gutter-md">
      <div>
        <FilterCard>
          <q-form ref="filterForm" @submit="onFilter" @reset="onReset">
            <div class="row items-start q-col-gutter-md">
              <div class="col-xs-12 col-sm-6 col-md-3">
                <FormDate
                  label="Date From"
                  mask="date"
                  v-model="dateFrom"
                  :value="dateFrom"
                  :rules="[VALIDATION.required]"
                  @input="onSelectFromDate"
                  tabindex="1"
                />
              </div>

              <div class="col-xs-12 col-sm-6 col-md-3">
                <FormDate
                  label="Date To"
                  mask="date"
                  v-model="dateTo"
                  :value="dateTo"
                  :rules="[VALIDATION.required]"
                  @input="onSelectToDate"
                  tabindex="2"
                />
              </div>

              <div class="col-xs-12 col-sm-6 col-md-3">
                <SelectUserStores
                  v-model="store"
                  :values="store"
                  @updateStore="$event => (store = $event)"
                  tabindex="3"
                />
              </div>

              <div
                class="flex justify-end gap-4 col-xs-12 col-sm-12 col-md-3 text-right"
              >
                <Button
                  variant="primary"
                  label="Reset"
                  icon="refresh"
                  type="reset"
                  outline
                  tabindex="4"
                />
                <Button
                  variant="primary"
                  label="Filter"
                  icon="o_filter_alt"
                  :loading="loadingSales"
                  :disabled="loadingSales"
                  type="submit"
                  tabindex="5"
                />
              </div>
            </div>
          </q-form>
        </FilterCard>
      </div>

      <Loader :loading="loadingSales" />

      <NoDataCard v-if="noData" />

      <div class="col q-col-gutter-md" v-if="hasData">
        <div class="row q-col-gutter-md">
          <div class="col-xs-12 col-sm-6 col-md-4">
            <SimpleCard
              variant="primary"
              title="Sales"
              :value="FORMAT.toCurrency(totalSales.sales)"
            />
          </div>

          <div class="col-xs-12 col-sm-6 col-md-4">
            <SimpleCard
              variant="success"
              title="Trx Count"
              :value="FORMAT.toNumber(totalSales.trxCount)"
            />
          </div>

          <div class="col-xs-12 col-sm-6 col-md-4">
            <SimpleCard
              variant="info"
              title="AVE. BASKET SIZE"
              :value="
                FORMAT.toNumber(FORMAT.toFixedNumber(totalSales.basketSize))
              "
            />
          </div>
        </div>

        <div>
          <q-card class="q-pa-lg">
            <q-card-section>
              <BarChart
                id="dailySalesChart"
                type="DailySales"
                axis="xy"
                xLabel="Date"
                yLabel="Net Sales"
                xData="month"
                yData="sales"
                :data="salesTableData"
              />
            </q-card-section>
          </q-card>
        </div>

        <div>
          <Table
            :rows="salesTableData"
            :columns="salesTableHeader"
            :loading="loadingSales"
          />
        </div>
      </div>
    </div>
  </PageContent>

  <Modal
    :show="showModal"
    header="Help"
    width="700px"
    cancelLabel="Close"
    @close="onToggleModal"
    closeOnly
  >
    <h5 class="q-mb-md">Daily Sales</h5>
    <p>Generate daily sales by date range, store</p>

    <q-separator class="q-my-md" />

    <h6 class="q-mb-md">Filters</h6>
    <p><strong>Date From:</strong> required field</p>
    <p><strong>Date To:</strong> required field</p>
    <p><strong>Store:</strong> required field</p>
  </Modal>
</template>

<script>
import { ref, computed, watch, onBeforeMount } from 'vue'
import PageHeader from '@/components/PageHeader'
import PageContent from '@/components/PageContent'
import { SimpleCard, FilterCard, NoDataCard } from '@/components/cards'
import { FormDate } from '@/components/inputs'
import Button from '@/components/Button'
import Table from '@/components/Table'
import Modal from '@/components/Modal'
import { BarChart } from '@/components/charts'
import Loader from '@/components/Loader'
import SelectUserStores from '@/components/customs/SelectUserStores'

import {
  DATE,
  FORMAT,
  VALIDATION,
  EXPORT,
  Toast,
  fetchData,
  getIconUrl
} from '@/tools'

export default {
  name: 'StoreDailySales',
  components: {
    PageHeader,
    PageContent,
    SimpleCard,
    FilterCard,
    NoDataCard,
    FormDate,
    Button,
    Table,
    Modal,
    BarChart,
    Loader,
    SelectUserStores
  },
  setup() {
    const { showToast } = Toast()
    const {
      data: dataSales,
      error: errorSales,
      loading: loadingSales,
      post: postSales
    } = fetchData()

    const showModal = ref(false)
    const filterForm = ref(null)
    const dateFrom = ref(DATE.toFriendlyDate(new Date()))
    const dateTo = ref(DATE.toFriendlyDate(new Date()))
    const store = ref([])
    const totalSales = ref({
      sales: 0,
      trxCount: 0,
      basketSize: 0
    })
    const salesTableData = ref(null)

    const salesTableHeader = ref([
      {
        name: 'month',
        label: 'Business Date',
        field: 'month',
        align: 'center',
        sortable: true
      },
      {
        name: 'trx_count',
        label: 'Transaction Count',
        field: 'trx_count',
        align: 'center',
        sortable: true
      },
      {
        name: 'quantity',
        label: 'Item Quantity',
        field: 'quantity',
        align: 'center',
        sortable: true
      },
      {
        name: 'sales',
        label: 'Net Sales',
        field: row => FORMAT.toCurrency(row.sales),
        align: 'center',
        sortable: true
      },
      {
        name: 'basket_size',
        label: 'Basket Size',
        field: 'basket_size',
        align: 'center',
        sortable: true
      }
    ])

    const hasData = computed(
      () => salesTableData.value && salesTableData.value.length > 0
    )
    const noData = computed(
      () => salesTableData.value && salesTableData.value.length === 0
    )

    const onToggleModal = () => {
      showModal.value = !showModal.value
    }

    const onFilter = () => {
      filterForm.value.validate().then(success => {
        if (success) {
          const payload = {
            filters: {
              date_from: DATE.toFriendlyDate(dateFrom.value),
              date_to: DATE.toFriendlyDate(dateTo.value),
              stores: store.value
            }
          }

          postSales('v1/sales/daily-sales/detailed', payload)
        } else {
          showToast('Please fill all the required fields', 'info')
        }
      })
    }

    const onReset = () => {
      filterForm.value.resetValidation()
      dateFrom.value = DATE.toFriendlyDate(new Date())
      dateTo.value = DATE.toFriendlyDate(new Date())
      store.value = []
      salesTableData.value = null
    }

    const onSelectFromDate = data => {
      dateFrom.value = data
    }

    const onSelectToDate = data => {
      dateTo.value = data
    }

    const exportTable = () => {
      EXPORT.exportToExcel(
        salesTableHeader.value,
        salesTableData.value,
        'daily_sales'
      )
    }

    onBeforeMount(() => {
      watch([dataSales, errorSales, loadingSales], () => {
        if (loadingSales.value) {
          salesTableData.value = null
        } else {
          if (errorSales?.value) {
            showToast('There was a problem fetching sales.', 'danger')
          } else if (dataSales?.value) {
            const salesTotal = dataSales?.value?.reduce((a, b) => {
              return a + b.sales
            }, 0)

            const trxCountTotal = dataSales?.value?.reduce((a, b) => {
              return a + b.trx_count
            }, 0)

            salesTableData.value = dataSales?.value?.map(item => ({
              month: DATE.toFriendlyDate(item.business_date),
              basket_size: FORMAT.toNumber(item.basket_size),
              quantity: FORMAT.toNumber(item.quantity),
              sales: item.sales,
              trx_count: FORMAT.toNumber(item.trx_count)
            }))

            totalSales.value = {
              sales: salesTotal,
              trxCount: trxCountTotal,
              basketSize: salesTotal / trxCountTotal
            }
          } else {
            salesTableData.value = []
          }
        }
      })
    })

    return {
      showModal,
      filterForm,
      dateFrom,
      dateTo,
      store,
      salesTableHeader,
      salesTableData,
      totalSales,
      loadingSales,
      FORMAT,
      VALIDATION,
      getIconUrl,
      hasData,
      noData,
      onToggleModal,
      onFilter,
      onReset,
      onSelectFromDate,
      onSelectToDate,
      exportTable
    }
  }
}
</script>
