<template>
  <StoreDailySales />
</template>

<script>
import StoreDailySales from '@/components/pages/sales/StoreDailySales'

export default {
  components: {
    StoreDailySales
  }
}
</script>
